import React from "react"
import { Link } from "gatsby"
import LargeTwitter from "../images/shoyaright_icon_twitter_card_large_summary.png"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Shoya, Inc." />
    <img style={{ display: "none" }} src={LargeTwitter} alt="" />
    <div className="container">
      <div id="shoyaLogo" />
      <div id="nav">
        <Link to="/about" exact="true">
          About
        </Link>
        <Link to="/light-camera" exact="true">
          Shoya Light & Camera
        </Link>
        <Link to="/shoyaright/" exact="true">
          The Shoyaright!
        </Link>
      </div>
      <div className="copyright">
        <p>&copy; Shoya, Inc. 2020 - &infin;</p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
